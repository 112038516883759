/* eslint-disable global-require */

import {
  BotFeature, CogCreator, FaqContent, NavbarTab, PremiumTier, RulesContent,
} from '@/types/main';

export const navBarTabs: NavbarTab[] = [
  {
    id: 'home-btn',
    text: 'Home',
    link: '/',
    icon: ['fas', 'home'],
  },
  {
    id: 'commands-btn',
    text: 'Commands',
    link: '/commands',
    icon: ['fas', 'keyboard'],
  },
  {
    id: 'art-btn',
    text: 'Art',
    link: '/art',
    icon: ['fas', 'paint-brush'],
  },
  {
    id: 'status-btn',
    text: 'Status',
    link: '/status',
    icon: ['fas', 'check-circle'],
  },
  {
    id: 'premium-btn',
    text: 'Premium',
    link: '/premium',
    icon: ['fas', 'heart'],
  },
  {
    id: 'discord-server-btn',
    text: 'Discord Server',
    link: '//discord.gg/yXJwfjkp27',
    icon: ['fab', 'discord'],
    isRouterLink: false,
    openExternally: true,
  },
  {
    id: 'invite-btn',
    text: 'Invite me',
    link: '//invite.martinebot.com',
    icon: ['fas', 'plus'],
    isRouterLink: false,
    openExternally: true,
  },
];

export const botFeatures: BotFeature[] = [
  {
    title: 'Leveling',
    description: 'Increase your members engagement with an advanced leveling system, including many settings from exp, to channels/roles ignore, or roles rewards',
    icon: ['fas', 'chart-line'],
  },
  {
    title: 'Giveaways',
    description: 'Host community giveaways with a simple prompt that will walk you through setting up your giveaway, with configurable defaults, and reroll function',
    icon: ['fas', 'gift'],
  },
  {
    title: 'Games Stats',
    description: 'Get your stats and more informations from multiple games, including League of Legends, Rocket League, Osu, Fortnite and Minecraft!',
    icon: ['fas', 'gamepad'],
  },
  {
    title: 'NSFW',
    description: 'Tons of nsfw categories to view images with a simple command, send images to anyone who consents to receive, or send images automatically to any nsfw channel',
    icon: ['fas', 'grin-wink'],
  },
  {
    title: 'Images',
    description: 'Random images of anything, from cats and ducks, to memes and nekos, to wallpapers and art, and any search from Imgur, Giphy or any subreddit',
    icon: ['fas', 'images'],
  },
  {
    title: 'General',
    description: 'A global economy, detailed weather and forecasts for any location, and users interaction commands such as hug, pat, or ship',
    icon: ['fas', 'comment'],
  },
  {
    title: 'Welcoming Members',
    description: 'Say welcome (or goodbye) to your members with an rendered image (or just a simple message) using the imgwelcome and welcomeset commands',
    icon: ['fas', 'user-plus'],
  },
  {
    title: 'Alerts',
    description: 'Notify when a Twitch, Kick or YouTube stream is going live, by using the alerts command',
    icon: ['fas', 'bell'],
  },
  {
    title: 'Moderation',
    description: 'Moderation tools to help you and your moderators with kick, ban and mute commands, as well as extensive logging options',
    icon: ['fas', 'gavel'],
  },
  {
    title: 'Multi-language',
    description: 'Martine is available in many languages, try the /language command in your server to see the available languages',
    icon: ['fas', 'language'],
  },
];

export const UserPremiumTiers: PremiumTier[] = [
  {
    title: 'Supporter',
    price: '$1',
    description: 'For 30 days after activation, for one user in all servers.',
    perks: [
      'Bypass vote requirements.',
      '"Supporter" role on Martine\'s server.',
    ],
    pledgeId: '7435875',
    highlighted: false,
  },
  {
    title: 'User Premium',
    price: '$3',
    description: 'For 30 days after activation, for one user in all servers.',
    perks: [
      'Bypass vote requirements.',
      'Change profile backgrounds without paying 2k marts.',
      'Up to 30 tags on the booru commands.',
      '"Premium User" role on Martine\'s server.',
    ],
    pledgeId: '7114435',
    highlighted: false,
  },
];

export const ServerPremiumTiers: PremiumTier[] = [
  {
    title: 'Server Premium Silver',
    price: '$5',
    description: 'For 30 days after activation, for one server.',
    perks: [
      'Autoporn channels limit up to 15.',
      'Ability to change delay and amount of images of autoporn',
      'Up to 30 tags on the booru commands.',
      'Up to 30 alerts per website',
      'Removes notifications about getting premium.',
      'User Premium for the purchaser.',
      'Ability to change leveling cooldown and message length.',
      'Change profile backgrounds without paying 2k marts for all members of the server.',
      '"Premium Server Silver" role on Martine\'s server for the purchaser.',
    ],
    pledgeId: '3322652',
    highlighted: false,
  },
  {
    title: 'Server Premium Gold',
    price: '$10',
    description: 'For 30 days after activation, for one server.',
    perks: [
      'All previous server rewards.',
      'Autoporn channels limit up to 25.',
      'Up to 50 alerts per website',
      'Bypass vote requirements for all members of the server.',
      '"Premium Server Gold" on Martine\'s server for the purchaser.',
    ],
    pledgeId: '6992188',
    highlighted: true,
  },
  {
    title: 'Server Premium Platinum',
    price: '$18.50',
    description: 'For 30 days after activation, for five servers.',
    perks: [
      'Server Premium Gold, but in five servers.',
      '"Premium Server Platinum" on Martine\'s server for the purchaser.',
    ],
    pledgeId: '7114502',
    highlighted: false,
  },
];

export const rulesContent: RulesContent[] = [
  {
    title: 'Cheats and spam',
    description: 'Selfbot usage or cheats to abuse the bot economy are not allowed. Mass spam of commands is also highly discouraged and may result in a ban as well.',
  },
  {
    title: 'Global slurs',
    description: 'This includes but is not limited to: racism, sexism, homophobia, or any other slurs. Using the bot for any of those cited before will NOT be tolerated. No commands were intended for this behavior.',
  },
  {
    title: 'Respect the artist’s work',
    description: "Martine's avatar/artworks/emojis, were commissioned and paid for by the owner. Please respect the artist’s work. If anyone is caught using any of the arts commissioned for Martine for whatever use they will be warned and asked to either delete what they have stolen or credit the artist.",
  },
  {
    title: 'Discord guidelines and ToS',
    description: 'Discord guidelines and ToS also must be respected through the bot. The full terms can be found here: https://discordapp.com/guidelines and https://discordapp.com/terms',
  },
];

export const cogCreators: CogCreator[] = [
  {
    name: 'aikaterna',
    url: 'https://github.com/aikaterna/aikaterna-cogs',
  },
  {
    name: 'Ellie',
    url: 'https://github.com/elijabesu/SauriCogs',
  },
  {
    name: 'Flame',
    url: 'https://github.com/Flame442/FlameCogs',
  },
  {
    name: 'kennnyshiwa',
    url: 'https://github.com/kennnyshiwa/kennnyshiwa-cogs',
  },
  {
    name: 'Laggron',
    url: 'https://github.com/retke/Laggrons-Dumb-Cogs',
  },
  {
    name: 'Neuro Assassin',
    url: 'https://github.com/NeuroAssassin/Toxic-Cogs',
  },
  {
    name: 'Predä',
    url: 'https://github.com/PredaaA/predacogs',
  },
  {
    name: 'Redjumpman',
    url: 'https://github.com/Redjumpman/Jumper-Plugins',
  },
  {
    name: 'Sinbad',
    url: 'https://github.com/mikeshardmind/SinbadCogs/tree/v3',
  },
  {
    name: 'TrustyJAID',
    url: 'https://github.com/TrustyJAID/Trusty-cogs',
  },
  {
    name: 'Jackenmen',
    url: 'https://github.com/jack1142/JackCogs',
  },
  {
    name: 'Fixator10',
    url: 'https://github.com/fixator10/Fixator10-Cogs',
  },
  {
    name: 'Kowlin',
    url: 'https://github.com/Kowlin/Sentinel',
  },
];

export const martineContributors: string[] = [
  'kennnyshiwa#1337',
  'Jackenmen#6607',
  'Rinza#2539',
  'Neuro Assassin#4779',
  'aikaterna#1393',
  'Draper#6666',
  'Flame#2941',
  'Kowlin#2536',
  'Bread#0007',
  'Sinihan#9537',
];

export const faqData: FaqContent[] = [
  {
    id: 0,
    question: 'How to invite Martine to my server?',
    answer: (
      'Here is the invitation link:<br/>'
      + '<a href="https://invite.martinebot.com" rel="noopener" target="_blank">https://invite.martinebot.com</a>'
    ),
  },
  {
    id: 1,
    question: 'How to use Martine commands?',
    answer: (
      'Get started with <code>,help</code> command, it will list you all available categories. Or type <code>/</code> to find all available slash commands'
      + ' Use <code>,help &lt;category&gt;</code> to see all commands of a category. Or you can see all commands above.'
    ),
  },
  {
    id: 2,
    question: 'What is the default prefix, and how can I change it?',
    answer: (
      'Default prefix is <code>,</code> but cannot be changed anymore, you should now use Discord\'s slash commands <code>/</code> instead.'
      + 'Join <a href="http://discord.gg/yXJwfjkp27" rel="noopener" target="_blank">Martine\'s server</a> for more information about this!'
    ),
  },
  {
    id: 3,
    question: 'Why is Martine not answering any command?',
    answer: (
      'Please check the following points:<br/>'
      + '- Mention <code>@Martine</code>, look if the bot is online in your server, if it\'s not take a look at <code>#bot-status</code> channel on '
      + '<a href="http://discord.gg/yXJwfjkp27" rel="noopener" target="_blank">Martine\'s server</a> to know if there is an incident. '
      + 'Mentionning the bot should also respond you with everything to get started.<br/>'
      + '- Check that Martine has access to the channel, and can send messages.<br/>'
      + '- Make sure that you are using an available prefix.<br/><br/>'
      + 'If nothing helped, ask in <code>#support</code> on <a href="http://discord.gg/yXJwfjkp27" rel="noopener" target="_blank">Martine\'s server</a>.'
    ),
  },
  {
    id: 4,
    question: 'How to disable (and enable back) commands in my server, or in a specific channel?',
    answer: (
      'First of all, you must be Administrator of the server to be able to do this.<br/>Note: You can disable/enable multiple commands at once.<br/><br/>'
      + 'For the entire server:<br/>'
      + '- To disable: <code>,command disable server &lt;commands&gt;</code><br/>'
      + '- To enable: <code>,command enable server &lt;commands&gt;</code><br/><br/>'
      + 'For the current channel only:<br/>'
      + '- To disable: <code>,command disable channel &lt;commands&gt;</code><br/>'
      + '- To enable: <code>,command enable channel &lt;commands&gt;</code>'
    ),
  },
  {
    id: 5,
    question: 'How to configure autoporn in my server?',
    answer: (
      'First make sure that you are in an NSFW channel, otherwise it will not work. If you are not sure of how to setup an NSFW channel, '
      + '<a href="https://support.discord.com/hc/en-us/articles/115000084051-NSFW-Channels-and-Content" rel="noopener" target="_blank">take a look at this guide</a>.<br/><br/>'
      + 'Once you have an NSFW channel, simply use the <code>/autoporn</code> command. It will send you a message with buttons to enable/disable, select categories, and see the status in the server.'
    ),
  },
  {
    id: 6,
    question: 'How do I make Martine send welcome/leaves messages?',
    answer: (
      'Use <code>,welcomeset welcome toggle</code> for welcomes, and <code>,welcomeset leave toggle</code> for leaves. It will enable in the current channel.<br/>'
      + 'You can manage messages sent by using <code>,welcomeset welcome|leave add</code> to add a message, and <code>,welcomeset welcome|leave del</code> to remove a message, '
      + 'then <code>,welcomeset welcome|leave list</code> to see all messages.<br/>'
      + 'For all available settings take a look at <code>,welcomeset subcommands</code>.'
    ),
  },
];
