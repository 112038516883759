import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=3aef4928&scoped=true"
import script from "./Navbar.vue?vue&type=script&lang=ts"
export * from "./Navbar.vue?vue&type=script&lang=ts"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=3aef4928&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__lodash@4.17.21__s6l3nt2j5z2qyyvij7jcrz2r5m/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aef4928",
  null
  
)

export default component.exports